import { createWebHistory, createRouter } from "vue-router";

import CNCDesigns from "../pages/CNCDesigns.vue";
import PillarDesigns from "../pages/PillarDesigns.vue";
import MandirDesigns from "../pages/MandirDesigns.vue";
import RoundAndSquareDesigns from "../pages/RoundAndSquareDesigns.vue";
//import HomePage from "../pages/HomePage.vue";
import ProductsPage from "../pages/ProductsPage.vue";
import CatalogsPage from "../pages/CatalogsPage.vue";

const routes = [
  {
    path: "/",
    component: CatalogsPage,
  },
  {
    path: "/CNCDesigns(8x4)",
    component: CNCDesigns,
  },
  {
    path: "/PillarDesigns",
    component: PillarDesigns,
  },
  {
    path: "/MandirDesigns",
    component: MandirDesigns,
  },
  {
    path: "/RoundAndSquareDesigns",
    component: RoundAndSquareDesigns,
  },
  {
    path: "/Products",
    component: ProductsPage,
  },
  {
    path: "/Catalogs",
    component: CatalogsPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
