<template>
  <div class="q-mt-md">
    <q-carousel
      swipeable
      animated
      v-model="slide"
      ref="carousel"
      infinite
      arrows
      navigation
      control-color="beige"
      transition-prev="slide-right"
      transition-next="slide-left"
      height="163px"
    >
      <q-carousel-slide
        v-for="n in 4"
        :key="n"
        :name="n"
        :img-src="require(`../assets/Banners/${n}.jpg`)"
        @click="redirectToSlide(n)"
        style="
          background-repeat: no-repeat;
          background-size: contain;
          background-color: black;
        "
      />

      <!--   <template v-slot:control>
        <q-carousel-control
          position="bottom-right"
          :offset="[18, 18]"
          class="q-gutter-xs"
        >
          <q-btn
            push
            round
            dense
            color="orange"
            text-color="black"
            icon="arrow_left"
            @click="$refs.carousel.previous()"
          />
          <q-btn
            push
            round
            dense
            color="orange"
            text-color="black"
            icon="arrow_right"
            @click="$refs.carousel.next()"
          />
        </q-carousel-control>
      </template> -->
    </q-carousel>
  </div>
</template>

<script setup>
import router from "@/router";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
const slide = ref(1);
const route = useRoute();

onMounted(() => {
  showCurrentSlide(route.path);
});

const redirectToSlide = (n) => {
  switch (n) {
    case 1:
      router.push("/CNCDesigns(8x4)");
      break;
    case 2:
      router.push("/PillarDesigns");
      break;
    case 3:
      router.push("/MandirDesigns");
      break;
    case 4:
      router.push("/RoundAndSquareDesigns");
      break;
    default:
      return;
  }
};

const showCurrentSlide = (currentSlide) => {
  switch (currentSlide) {
    case "/CNCDesigns(8x4)":
      slide.value = 1;
      break;
    case "/PillarDesigns":
      slide.value = 2;
      break;
    case "/MandirDesigns":
      slide.value = 3;
      break;
    case "/RoundAndSquareDesigns":
      slide.value = 4;
      break;
    default:
      return;
  }
};
</script>

<style scoped>
.scroll {
  overflow: hidden;
}
</style>
