<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container> <router-view /></q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
//import CarouselComponent from "./components/CarouselComponent.vue";
//import HeaderComponent from "./components/HeaderComponent.vue";
//import CNCDesigns from "./pages/CNCDesigns.vue";
export default {
  name: "LayoutDefault",

  /*  components: {
    CNCDesigns,
  }, */

  setup() {
    return {
      leftDrawerOpen: ref(false),
    };
  },
};
</script>
<style>
html,
body {
  margin: 0px;
  height: 100%;
}
</style>
