<template>
  <q-card class="my-card q-my-md" v-for="n in props.count" :key="n">
    <q-img
      @contextmenu="test"
      :src="require(`../assets/${props.name}/${n}.jpg`)"
    >
    </q-img>
  </q-card>
</template>
<script setup>
import { defineProps } from "vue";
const props = defineProps(["name", "count"]);

const test = (e) => {
  e.preventDefault();
};
</script>
