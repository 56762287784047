<template>
  <q-header elevated id="header" :style="{ backgroundColor: randomColor }">
    <q-toolbar>
      <q-avatar @click="router.push('/')">
        <img src="../assets/aakriti_logo.jpeg" />
      </q-avatar>
      <q-toolbar-title class="text-capitalize cursor-pointer">{{
        props.name
      }}</q-toolbar-title>
    </q-toolbar>
  </q-header>
</template>
<script setup>
import { ref, defineProps } from "vue";
import router from "@/router";
const props = defineProps(["name"]);
const randomColor = ref("#ff0000");
function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  randomColor.value = color;
}

setInterval(getRandomColor, 1000);
</script>
