<template>
  <HeaderComponent :name="'CNC Designs'" />
  <CarouselComponent />
  <ImagesComponent :name="'Jaali_8x4_with_Name_72'" :count="100" />
</template>

<script>
import CarouselComponent from "../components/CarouselComponent.vue";
import HeaderComponent from "../components/HeaderComponent.vue";
import ImagesComponent from "../components/ImagesComponent.vue";
export default {
  name: "LayoutDefault",

  components: {
    CarouselComponent,
    HeaderComponent,
    ImagesComponent,
  },
};
</script>
