<template>
  <div class="row">
    <q-card
      class="my-card q-ma-md"
      v-for="n in 4"
      :key="n"
      :name="n"
      @click="redirectToSlide(n)"
    >
      <q-img :src="require(`../assets/Banners/${n}.jpg`)"> </q-img>
    </q-card>
  </div>
</template>

<script setup>
import router from "@/router";
const redirectToSlide = (n) => {
  switch (n) {
    case 1:
      router.push("/CNCDesigns(8x4)");
      break;
    case 2:
      router.push("/PillarDesigns");
      break;
    case 3:
      router.push("/MandirDesigns");
      break;
    case 4:
      router.push("/RoundAndSquareDesigns");
      break;
    default:
      return;
  }
};

/* const getTitle = (n) => {
  switch (n) {
    case 1:
      return "CNCDesigns(8x4)";
    case 2:
      return "PillarDesigns";
    case 3:
      return "MandirDesigns";
    case 4:
      return "RoundAndSquareDesigns";

    default:
      return;
  }
}; */
</script>

<style lang="scss" scoped>
.my-card {
  width: 100%;
  height: 50%;
}
</style>
